var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/assets/aboutUs/us.png"),
          width: "100%",
          height: "600px",
        },
      }),
      _c("div", { staticClass: "title" }, [_vm._v("荣誉证书")]),
      _c("div", { staticClass: "contents" }, [
        _c("div", { staticStyle: { margin: "0 auto", width: "80%" } }, [
          _c("div", { staticClass: "title" }, [_vm._v("ISO认证")]),
          _c("div", { staticClass: "img_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/zqhx1.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/zqhx2.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/zqhx3.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("ITSS")]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/ITSS1.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/ITSS2.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/ITSS3.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("产品证书")]),
          _c("div", { staticClass: "img_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor1.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor2.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/softpro1.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/softpro2.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("合作伙伴")]),
          _c("div", { staticClass: "img_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/f5.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/hw.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("软件著作")]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor3.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor4.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor5.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor6.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor7.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor8.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor9.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor10.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor11.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/honor12.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/soft1.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/soft2.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/soft3.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/softb1.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/softb2.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("商标注册")]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg1.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg2.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg3.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg4.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg5.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg6.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg7.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg8.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg9.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg10.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg11.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg12.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg13.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg14.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg15.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/reg16.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/ly.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("兼容性认证")]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/suma.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/suma1.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/suma2.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/hygon.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/hygonh1.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/tongxin.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/kylin.png"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v("其他荣誉")]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/s1.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/h1.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/h3.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/s4.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "box_flex" }, [
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/s3.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/s2.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/s5.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
            _c("div", { staticClass: "img_item" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/honor/s6.jpg"),
                  width: "100%",
                  height: "auto",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }