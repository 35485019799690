<template>
    <div>
        <img src="@/assets/aboutUs/us.png" width="100%" height="600px" />
        <div class="title">荣誉证书</div>
        <div class="contents">
            <!-- 轮播 -->
            <div style="margin: 0 auto; width: 80%">
                <div class="title">ISO认证</div>
                <div class="img_flex">
                    <div class="img_item"><img src="@/assets/honor/zqhx1.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/zqhx2.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/zqhx3.png" width="100%" height="auto" /></div>
                </div>
                <div class="title">ITSS</div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/ITSS1.jpg" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/ITSS2.jpg" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/ITSS3.jpg" width="100%" height="auto" /></div>
                </div>
                <div class="title">产品证书</div>
                <div class="img_flex">
                    <div class="img_item"><img src="@/assets/honor/honor1.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/honor2.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/softpro1.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/softpro2.png" width="100%" height="auto" /></div>
                </div>
                <div class="title">合作伙伴</div>
                <div class="img_flex">
                    <div class="img_item"><img src="@/assets/honor/f5.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/hw.png" width="100%" height="auto" /></div>
                </div>
                <div class="title">软件著作</div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/honor3.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/honor4.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/honor5.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/honor6.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/honor7.png" width="100%" height="auto" /></div>
                </div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/honor8.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/honor9.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/honor10.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/honor11.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/honor12.png" width="100%" height="auto" /></div>
                </div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/soft1.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/soft2.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/soft3.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/softb1.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/softb2.png" width="100%" height="auto" /></div>
                </div>
                <div class="title">商标注册</div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/reg1.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg2.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg3.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg4.png" width="100%" height="auto" /></div>
                </div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/reg5.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg6.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg7.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg8.png" width="100%" height="auto" /></div>
                </div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/reg9.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg10.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg11.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg12.png" width="100%" height="auto" /></div>
                </div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/reg13.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg14.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg15.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/reg16.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/ly.png" width="100%" height="auto" /></div>
                </div>
                <div class="title">兼容性认证</div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/suma.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/suma1.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/suma2.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/hygon.png" width="100%" height="auto" /></div>
                </div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/hygonh1.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/tongxin.png" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/kylin.png" width="100%" height="auto" /></div>
                </div>
                <div class="title">其他荣誉</div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/s1.jpg" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/h1.jpg" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/h3.jpg" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/s4.jpg" width="100%" height="auto" /></div>
                </div>
                <div class="box_flex">
                    <div class="img_item"><img src="@/assets/honor/s3.jpg" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/s2.jpg" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/s5.jpg" width="100%" height="auto" /></div>
                    <div class="img_item"><img src="@/assets/honor/s6.jpg" width="100%" height="auto" /></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Honor',
    data() {
        return {}
    },
    methods: {},
    mounted() {}
}
</script>

<style scoped>
.img_item {
    flex: 1;
    margin: 0 20px;
}
.box_flex {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;
}
.flex_item {
    margin-bottom: 20px;
}
.img_flex {
    flex: 1;
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: center;
    align-items: stretch;
    align-content: center;
}
.title {
    text-align: center;
    color: #191c3d;
    font-size: 32px;
    font-weight: bold;
    margin: 30px 0;
}
.contents {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 40px;
}
</style>
